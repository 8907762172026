import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import localFont from 'next/font/local';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { useRouter } from 'next/router';
// import { Provider, ErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

import 'styles/styles.css';

import GlobalStateProvider from 'contexts/global-state-context';
import WordPressProvider from 'contexts/wordpress-provider';
import BrazeProvider from 'contexts/braze-context';

import AppHead from 'components/head';
import Error from './_error';
import { EventsTrackerProvider } from 'contexts/eventsTracker';
import { POSTHOG_API_HOST, POSTHOG_API_KEY } from 'lib/posthog';
import { getPosthogBootstrapCookie } from 'lib/cookies/posthog-cookie';

const PreviewBar = dynamic(() => import('components/preview-bar'));

declare global {
  interface Window {
    routeTimeout?: NodeJS.Timeout;
  }
}

const sohne = localFont({
  variable: '--font-sohne',
  src: [
    {
      path: '../../components/assets/fonts/sohne-buch.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../components/assets/fonts/sohne-kraftig.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
  display: 'swap',
});
const sohneBreit = localFont({
  variable: '--font-sohneBreit',
  src: [
    {
      path: '../../components/assets/fonts/sohne-breit-kraftig.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../components/assets/fonts/sohne-breit-halbfett.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
  display: 'swap',
});

// Posthog initialization
if (typeof window !== 'undefined') {
  const flags = getPosthogBootstrapCookie();
  let bootstrapData = {};

  if (flags) {
    bootstrapData = JSON.parse(flags);
  }

  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_API_HOST,
    autocapture: false,
    bootstrap: bootstrapData,
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

const App: React.FC<AppProps<any>> = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url, { shallow }) => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
      }

      if (shallow) {
        return;
      }

      window.routeTimeout = setTimeout(() => {
        if (url !== window.location.pathname) {
          window.location.assign(url);
        }
      }, 150);
    };

    const handleStop = () => {
      window?.routeTimeout && clearTimeout(window?.routeTimeout);
    };

    const handleComplete = () => {
      posthog?.capture('$pageview');
      handleStop();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleStop);
    };
  }, []);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${sohne.style.fontFamily};
        }
      `}</style>
      <AppHead webmasterMeta={pageProps?.seoSettings?.webmaster} />
      {process.env.NODE_ENV === 'production' ? (
        <Script
          id="leafwell-zaraz"
          type="text/javascript"
          src="https://zaraz.leafwell.com/cdn-cgi/zaraz/i.js"
        />
      ) : null}
      {process.env.NODE_ENV !== 'production' ? (
        <Script
          id="leafwell-trustpilot"
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
      ) : null}
      <div
        id="leafwell-container"
        className={`${sohne.variable} ${sohneBreit.variable}`}
      >
        {pageProps?.isPreview && <PreviewBar />}
        <PostHogProvider client={posthog}>
          {/* <Provider
          config={{
            accessToken: '59e5f45e087646fd8c7031e25a539344',
            environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
            // Change these 2 props to true after we've identified the Hubspot error
            captureUncaught: false,
            captureUnhandledRejections: false,
          }}
        >*/}
          {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar 
          <ErrorBoundary> */}
          <WordPressProvider value={pageProps?.settings ?? {}}>
            <GlobalStateProvider>
              <BrazeProvider>
                <EventsTrackerProvider>
                  {pageProps?.error ? (
                    <Error
                      key={router.asPath}
                      {...{
                        ...pageProps?.error,
                        title:
                          pageProps?.error?.message?.replace(/\.$/g, '') ||
                          'An unknown error occurred.',
                        statusCode: 500,
                      }}
                    />
                  ) : (
                    <Component {...pageProps} key={router.asPath} />
                  )}
                </EventsTrackerProvider>
              </BrazeProvider>
            </GlobalStateProvider>
          </WordPressProvider>
          {/*</ErrorBoundary>
        </Provider> */}
        </PostHogProvider>
      </div>
    </>
  );
};

export default App;
