import type { ErrorProps } from 'next/error';

import { useEffect } from 'react';
// import { useRollbar } from '@rollbar/react';
import { Paragraph } from '@leafwell/components';

import PageWrapMinimal from 'components/page-wrap-minimal';

const CustomError: React.FC<ErrorProps> = ({
  statusCode,
  title,
  ...errorInfo
}) => {
  // const rollbar = useRollbar();

  useEffect(() => {
    console.log('error debug', errorInfo);
    // rollbar.error(title, { ...errorInfo, statusCode });
  }, []);

  return (
    <PageWrapMinimal title="An error occurred">
      <section className="grid gap-y-3 w-full max-w-sm text-center">
        <Paragraph variant="medium">{title}</Paragraph>
        {errorInfo && Object.keys(errorInfo)?.length > 0 ? (
          <Paragraph
            variant="medium"
            size="small"
            className="grid w-full"
            tagName="footer"
          >
            Full error details:
            <pre className="w-full">{JSON.stringify(errorInfo)}</pre>
          </Paragraph>
        ) : (
          <Paragraph variant="medium">We're working on fixing this.</Paragraph>
        )}
      </section>
    </PageWrapMinimal>
  );
};

export default CustomError;
