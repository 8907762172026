import React from 'react';
import { Heading, Logo, ToastProvider } from '@leafwell/components';
import { NextSeo } from 'next-seo';

const PageWrapMinimal: React.FC<{
  children: React.ReactElement | React.ReactElement[];
  title: string;
}> = ({ children, title }) => {
  return (
    <>
      <NextSeo key="minimal-page-seo" title={title} />
      <ToastProvider>
        <section className="grid bg-white fixed top-0 left-0 w-full h-full items-center justify-items-center content-center">
          <Logo variant="icon" />
          <Heading level="3" className="text-center my-6">
            {title}
          </Heading>
          {children}
        </section>
      </ToastProvider>
    </>
  );
};

export default PageWrapMinimal;
